import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';

const Content = ({ content, medias, mediasGen }) => (
  <Container fluid className='wwd' id='whatwedo'>
    <Row className='row-full layout'>
      <Col md={6} className='wwd-content'>
        <h2
          dangerouslySetInnerHTML={{
            __html: medias !== undefined ? medias.name : content.title,
          }}
        ></h2>
        <img
          src={mediasGen !== '' ? mediasGen.logoRV.file : content.logo}
          height='32'
          className='logowwd'
          alt='img'
        />
        <p>{medias !== undefined ? medias.content : content.content}</p>
      </Col>
      <Col md={6}>
        <img
          src={medias !== undefined ? medias.file : content.image}
          className='d-inline-block circle'
          alt={medias !== undefined ? medias.name : content.image}
          width='100%'
        />
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = (state) => ({
  content: state.content,
  medias: state.medias.about,
  mediasGen: state.medias,
});

export default connect(mapStateToProps)(Content);
