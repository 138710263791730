import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';

const Footer = ({ footer, mediasGen, medias, anchors }) => {
  return (
    <Container fluid className='footerwidget'>
      <Row className='row-full layout hidden-xs'>
        <Col md={2} className='text-center'>
          <img
            src={mediasGen !== '' ? mediasGen.logoAlt.file : footer.logo1}
            width='60%'
            className='d-inline-block'
            alt={mediasGen !== '' ? mediasGen.logoAlt.name : footer.logo1}
          />
          <br />
          <br />
          <img
            src={mediasGen !== '' ? mediasGen.logoRV.file : footer.logo2}
            width='60%'
            className='d-inline-block'
            alt={mediasGen !== '' ? mediasGen.logoRV.name : footer.logo2}
          />
        </Col>
        <Col md={2} className='text-left footerwidgetcol'>
          <p>
            <a href={mediasGen !== '' ? anchors[0].link : footer.col1[0].link}>
              {footer.col1[0].text}
            </a>
            <br />
            <a href={mediasGen !== '' ? anchors[1].link : footer.col1[1].link}>
              {mediasGen !== '' ? anchors[1].file : footer.col1[1].text}
            </a>
          </p>
        </Col>
        <Col md={2} className='text-left footerwidgetcol'>
          <p>
            <a href={mediasGen !== '' ? anchors[2].link : footer.col2[0].link}>
              {mediasGen !== '' ? anchors[2].file : footer.col2[0].text}
            </a>
            <br />
            <a href={mediasGen !== '' ? anchors[3].link : footer.col2[1].link}>
              {footer.col2[1].text}
            </a>
          </p>
        </Col>
        {/* other */}
        <Col md={2} className='text-left footerwidgetcol'>
          <p>
            {medias !== undefined ? medias.name : footer.col3.p1}
            <br />
            {medias !== undefined ? medias.content : footer.col3.p2}
            <br />
            <a
              href={`mailto:${
                medias !== undefined ? medias.link : footer.col3.p3
              }`}
            >
              {medias !== undefined ? medias.link : footer.col3.p3}
            </a>
          </p>
        </Col>
        <Col md={2} className='text-left footerwidgetcol'>
          <p>
            {medias !== undefined ? medias.properties[0] : footer.col4.p1}
            <br />
            <b>
              {medias !== undefined ? medias.properties[1] : footer.col4.p2}
            </b>
          </p>
        </Col>
        <Col md={2} className='text-right footerwidgetcol'>
          <div className='btntop'>
            <a href='#top'>
              Back to Top
              <img src='./assets/sola-01.svg' className='rowclass' alt='img' />
            </a>
          </div>
        </Col>
      </Row>
      <Row className='row-full layout visible-xs'>
        <Col md={10} className='text-left col-xs-10'>
          <img
            src={footer.logo1}
            width='60%'
            className='d-inline-block'
            alt='Logo'
          />
          <br />
          <br />
          <br />
          <img
            src={footer.logo2}
            width='60%'
            className='d-inline-block'
            alt='Logo'
          />
        </Col>
        <Col md={2} className='text-left footerwidgetcol col-xs-2'>
          <div className='btntop'>
            <a href='#top'>
              Back to Top
              <img src='./assets/sola-01.svg' className='rowclass' alt='img' />
            </a>
          </div>
        </Col>
        <br />
        <hr className='hrfooter' />
        <Col sm={6} className='text-left footerwidgetcol col-xs-6'>
          <p>
            <a href={footer.col1[0].link}>{footer.col1[0].text}</a>
            <br />
            <a href={footer.col1[1].link}>{footer.col1[1].text}</a>
          </p>
        </Col>
        <Col sm={6} className='text-left footerwidgetcol col-xs-6'>
          <p>
            <a href={footer.col2mob[0].link}>{footer.col2mob[0].text}</a>
            <br />
            <a href={footer.col2mob[1].link}>{footer.col2mob[1].text}</a>
          </p>
        </Col>
        <hr className='hrfooter' />
        <Col sm={12} className='text-left footerwidgetcol'>
          <p>
            {footer.col3.p1}
            <br />
            {footer.col3.p2}
            <br />
            <a
              href={`mailto:${
                medias !== undefined ? medias.link : footer.col3.p3
              }`}
            >
              {medias !== undefined ? medias.link : footer.col3.p3}
            </a>
          </p>
        </Col>
        <Col sm={12} className='text-left footerwidgetcol'>
          <p>
            {footer.col4.p1}
            <br />
            <b>{footer.col4.p2}</b>
          </p>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  footer: state.footer,
  mediasGen: state.medias,
  medias: state.medias.footer,
  anchors: Object.values(state.medias)
    .filter((section) => section.section === 'anchors')
    .sort((a, b) => (a.content > b.content ? 1 : -1)),
});

export default connect(mapStateToProps)(Footer);
