import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';

const Homeplace = ({ homeplace, medias, icons }) => {
  return (
    <Container fluid className='homeplace' id='liferv'>
      <Row className='row-full layout'>
        <Col md={12} className='section3'>
          <h2>{medias != null ? medias.name : homeplace.title}</h2>
          <h3>{medias != null ? medias.section : homeplace.subtitle}</h3>
          <hr className='visible-xs hrline' />
          <p>{medias != null ? medias.content : homeplace.content}</p>
        </Col>
      </Row>
      <Row className='row-full layout hidden-xs'>
        <Col md={12} className='espaciadolateral'>
          <Row>
            {icons !== ''
              ? icons.map((icons) => (
                  <Col md={3} className='inconsection'>
                    <Row>
                      <Col md={5}>
                        <img
                          src={icons.file}
                          className='d-inline-block-homeplace'
                          alt={icons.name}
                          width='100%'
                        />
                      </Col>
                      <Col
                        md={7}
                        className={'elemento_texto ' + icons.properties[0]}
                      >
                        {icons.name}
                      </Col>
                    </Row>
                  </Col>
                ))
              : homeplace.icons.map((icons) => (
                  <Col md={3} className='inconsection'>
                    <Row>
                      <Col md={5}>
                        <img
                          src={icons.logo}
                          className='d-inline-block-homeplace'
                          alt='Logo'
                          width='100%'
                        />
                      </Col>
                      <Col md={7} className={'elemento_texto ' + icons.class}>
                        {icons.title}
                      </Col>
                    </Row>
                  </Col>
                ))}
            <Col md={3} className='inconsection'></Col>
          </Row>
        </Col>
      </Row>
      <Row className='row-full layout visible-xs'>
        <Col md={12}>
          <Carousel>
            {icons !== ''
              ? icons.map((icon) => (
                  <Carousel.Item>
                    <Row>
                      <Col xs={4}>
                        <img
                          src={icon.file}
                          className='d-inline-block'
                          alt={icon.name}
                        />
                      </Col>
                      <Col
                        xs={8}
                        className={'elemento_texto ' + icon.properties[1]}
                      >
                        {icon.name}
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))
              : homeplace.icons.map((icons) => (
                  <Carousel.Item>
                    <Row>
                      <Col xs={4}>
                        <img
                          src={icons.logo}
                          className='d-inline-block-homeplace'
                          alt='Logo'
                        />
                      </Col>
                      <Col
                        xs={8}
                        className={'elemento_texto ' + icons.classmob}
                      >
                        {icons.title}
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  homeplace: state.homeplace,
  medias: state.medias.homeplace,
  icons: Object.values(state.medias)
    .filter((section) => section.section === 'icons')
    .sort((a, b) => (a.key > b.key ? 1 : -1)),
});

export default connect(mapStateToProps)(Homeplace);
