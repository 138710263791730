import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

const Banner = ({ banner, medias }) => {
  return (
    <Container fluid className='main_banner' id='top'>
      <Row className='row-full layout'>
        <Col md={12} xs={12} className='row-full bannercontent'>
          <h1 className='dn'>{medias != null ? medias.name : banner.h1tag}</h1>
          <h2 className='titlebanner'>
            <span
              dangerouslySetInnerHTML={{
                __html: medias != null ? medias.name : banner.title,
              }}
            ></span>
          </h2>
          <h3 className='subtitlebanner'>
            {medias != null ? medias.section : banner.subtitle}.
          </h3>
          <Button
            variant='danger'
            className='btnbanner'
            href={medias != null ? medias.link : banner.c2a.link}
          >
            {medias != null ? medias.content : banner.c2a.text}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  banner: state.banner,
  medias: state.medias.herobanner,
});

export default connect(mapStateToProps)(Banner);
