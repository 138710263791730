import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { connect } from 'react-redux';

const Header = ({ header, medias, anchors }) => (
  <>
    <Container className='visible-xs visible-sm hidden-md' id='top'>
      <Row>
        <Col
          md={12}
          className='row-full-top pathbtnmob visible-xs visible-sm hidden-md'
        >
          <div className='btn-mission btnmob'>
            <a
              href={
                medias !== '' ? medias.missioncontrol.link : '#missioncontrol'
              }
            >
              {medias !== ''
                ? medias.missioncontrol.name
                : 'Contact Mission control'}
            </a>
          </div>
        </Col>
      </Row>
    </Container>
    <Container fluid>
      <div className='header-navigation'>
        <Navbar className='layout'>
          <Row className='row-full'>
            <Col md={5} className='al55'>
              <Navbar.Brand href='#'>
                <img
                  src={medias !== '' ? medias.logoRV.file : header.logo}
                  height='30'
                  className='d-inline-block align-top logo hidden-sm hidden-xs'
                  alt={medias !== '' ? medias.logoRV.name : header.logo}
                />
                <img
                  src={medias !== '' ? medias.logoRV.file : header.logo}
                  width='70%'
                  className='d-inline-block align-top logo visible-xs visible-sm'
                  alt={medias !== '' ? medias.logoRV.name : header.logo}
                />
              </Navbar.Brand>
            </Col>
            <Col md={7} className='text-center hidden-sm hidden-xs'>
              <Navbar.Toggle />
              <Navbar.Collapse className='pull-right'>
                <Nav
                  className='mr-auto my-2 my-lg-0 navbarmenu'
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                  {medias !== ''
                    ? anchors.map((anchor) => (
                        <Nav.Link
                          href={anchor.link}
                          className={
                            anchor.properties[0] === 'true'
                              ? 'btn-mission'
                              : 'non-special'
                          }
                        >
                          {anchor.name}
                        </Nav.Link>
                      ))
                    : header.anchors.map((anchor) => (
                        <Nav.Link
                          href={anchor.link}
                          className={
                            anchor.special === true
                              ? 'btn-mission'
                              : 'non-special'
                          }
                        >
                          {anchor.text}
                        </Nav.Link>
                      ))}
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Navbar>
      </div>
    </Container>
  </>
);

const mapStateToProps = (state) => ({
  header: state.header,
  medias: state.medias,
  anchors: Object.values(state.medias)
    .filter((section) => section.section === 'anchors')
    .sort((a, b) => (a.content > b.content ? 1 : -1)),
});

export default connect(mapStateToProps)(Header);
