import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Content from '../components/Content';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Homeplace from '../components/Homeplace';
import Contratacion from '../components/Contratacion';
import Jobs from '../components/Jobs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import '../embed_jobs.css';
import store from './store';
import { Provider } from 'react-redux';
// import apis from '../api';

function App() {
  // useEffect(() => {
  //     apis.getAllMedia().then(response => {
  //         let mediaDictionary = response.data.data.reduce((a,b)=>{
  //             a[b.key] = b;
  //             return a;
  //         },{})
  //         store.dispatch({
  //             type: 'ADD_MEDIA',
  //             allMedia: mediaDictionary
  //         })
  //     })
  // });

  return (
    <Provider store={store}>
      <Layout>
        <Header />
        <Banner />
        <Content />
        <Homeplace />
        <Contratacion />
        <Jobs />
        <Contact />
        <Footer />
      </Layout>
    </Provider>
  );
}

export default App;
