import { createStore } from '@reduxjs/toolkit';

const initialState = {
  header: {
    logo: './assets/rv_staffing_logo.svg',
    anchors: [
      {
        text: 'What we do',
        link: '#whatwedo',
        special: false,
      },
      {
        text: 'Life at RV',
        link: '#liferv',
        special: false,
      },
      {
        text: 'Process',
        link: '#hiringprocess',
        special: false,
      },
      {
        text: 'Contact Mission Control',
        link: '#missioncontrol',
        special: true,
      },
    ],
  },
  banner: {
    h1tag: 'International Recruiting Services For The World-Minded.',
    title: 'Where Do You<br>Want To Go?',
    subtitle: 'Take your career to the next level with a job at Red Valley',
    c2a: {
      text: 'Show Me My Dream Job',
      link: '#jobs',
    },
  },
  content: {
    title: 'WHAT WE DO',
    logo: './assets/rv_staffing_logo.svg',
    content:
      "RVStaffing is a specialized staffing firm and RedValley's recruitment arm-- meaning our job is to source the best of the best to join our powerhouse family and work with our clients to supercharge their businesses. We work with some of the US and Canada's most innovative companies in the medical, legal health + wellness, logistics, marketing (and more) industries and pair them with Colombia's top talent... you!",
    image: './assets/circles/circles.png',
  },
  homeplace: {
    title: 'A PLACE YOU CAN CALL HOME',
    subtitle:
      "We're proud to offer benefits that help you fell your best and do your best.",
    content:
      "At RVS, we invest in your development so that you can reach your full potential. We promise that when you start you career with us, you'll never stop learning and you'll never stop being challenged.",
    icons: [
      {
        logo: './assets/icons/1.svg',
        class: 'alp3',
        classmob: 'alp2',
        title: 'PRIVATE HEALTH INSURANCE',
      },
      {
        logo: './assets/icons/2.svg',
        class: 'alp2',
        classmob: 'alp1',
        title: 'RAISES FOR OVER ACHIEVING / GOOD PERFORMANCE',
      },
      {
        logo: './assets/icons/3.svg',
        class: 'alp3',
        classmob: 'alp1',
        title: 'CONTINUOUS CAREER DEVELOPMENT',
      },
      {
        logo: './assets/icons/4.svg',
        class: 'alp2',
        classmob: 'alp1',
        title: 'AUTO/MOTOR CYCLE INSURANCE DISCOUNTS',
      },
      {
        logo: './assets/icons/5.svg',
        class: 'alp4',
        classmob: 'alp3',
        title: 'REFERRAL BONUSES',
      },
      {
        logo: './assets/icons/6.svg',
        class: 'alp2',
        classmob: 'alp2',
        title: 'STATE OF THE ART HEADQUARTERS',
      },
      {
        logo: './assets/icons/7.svg',
        class: 'alp4',
        classmob: 'alp3',
        title: 'FLEXIBLE WORK',
      },
    ],
  },
  contratacion: {
    title: 'THE HIRING PROCESS',
    content: [
      "Are you up form the challenge? Here's what you can expect:",
      'Scroll trought our open positions below, and if a description matches your dream job, click Apply Now to submit your application and resume',
      'If your expriencice aligns with the role, our Staffing Consultant team will invite you to an exploration call. We love when candidates come to these conversations with questions!',
      'Our inteviews are a two-way conversation. We will be curious about your expriencice, interests, aspirations. and what brigs out your best at work - and we want you to learn all abouts us too. do you want to be part of our team?',
    ],
    accordion: [
      {
        key: '0',
        title: 'DEVELOPMENT <br> / DESIGN',
        text: 'Our team will ask you to demonstrate your knowledge and expertise through a number of conversations around your tech stack, coding, design, and development experience',
      },
      {
        key: '1',
        title: 'SALES',
        text: 'Focus your ambition and fulfil your potential working with one of our bold, talented and knowledgeable sales teams.',
      },
      {
        key: '2',
        title: 'OPERATIONS',
        text: 'Top professionals in HR and operations will assess your knowledge and problem-solving skills, and your ability to thrive in a fast-paced customer-centric team.',
      },
      {
        key: '3',
        title: 'FINANCE',
        text: 'Money is the lifeblood of any business and our finance and accounting teams are at the nerve centre.',
      },
      {
        key: '4',
        title: 'LEGAL',
        text: 'Grow your career in law with our internal legal teams or work for top-notch US law firms.',
      },
      {
        key: '0',
        title: 'CREATIVE <br> & MARKETING',
        text: "We believe creativity is intelligence having fun and that's exactly what our Creative and Marketing teams are and do. Our enthusedand talented creative teams deliver the unexpected and play a vital role in promoting RedValley and our amazing clients.",
      },
    ],
  },
  jobs: {
    title: 'JOBS',
    subtitle:
      "At RV, our office is a place for collaboration, creativity, change and drive. Want to get Involved? Check out our current vacancies on the RV team and with some of the best companies int he US (and not because we're baised)!",
  },
  contact: {
    title:
      'WANT TO GET IN TOUCH? <br/> LOOKING FOR <span class="text-danger-personalized">INTERNSHIPS</span>?',
    subtitle: "Drop us a note, and we'll be in contact with you soon!",
    form: [
      {
        label: 'Name',
        placeholder: 'Name',
      },
      {
        label: 'Last Name',
        placeholder: 'Last Name',
      },
      {
        label: 'E-mail',
        placeholder: 'E-mail',
      },
      {
        label: 'Mobile',
        placeholder: 'Mobile',
      },
      {
        label: 'Message',
        placeholder: 'Message',
      },
    ],
    c2a: 'Talk Soon!',
    messagesent: {
      text: 'Message sent!',
      class: 'alert alert-info',
    },
    messageunsent: {
      text: 'Message unsent, please try again',
      class: 'alert alert-warning',
    },
  },
  footer: {
    logo1: './assets/RedValley_vertical_white_logo.png',
    logo2: './assets/rv_staffing_logo.svg',
    col1: [
      {
        text: 'What We Do',
        link: '#whatwedo',
      },
      {
        text: 'RV Lifestyle',
        link: '#liferv',
      },
    ],
    col2: [
      {
        text: 'Hiring Process',
        link: '#hiringprocess',
      },
      {
        text: 'Get in Touch',
        link: '#missioncontrol',
      },
    ],
    col2mob: [
      {
        text: 'Hiring Process',
        link: '#hiringprocess2',
      },
      {
        text: 'Get in Touch',
        link: '#missioncontrol',
      },
    ],
    col3: {
      p1: 'Sao Paulo Plaza',
      p2: 'Medellín, Antioquia',
      p3: 'recruiting@rvstaffing.com',
    },
    col4: {
      p1: 'Copyright 2021 RV/DEV',
      p2: 'All Rights Reserved',
    },
  },
  medias: {
    lifeAtRV: {
      properties: [],
      _id: '615a5afe833bad8538f0df6d',
      key: 'lifeAtRV',
      file: 'RV Lifestyle',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-28T15:07:39.658Z',
      __v: 1,
      content: '2',
      link: '#liferv',
      name: 'Life at RV',
      section: 'anchors',
    },
    whatWeDo: {
      _id: '615d20e43916a61cd15c7de8',
      key: 'whatWeDo',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-11-12T19:23:08.514Z',
      __v: 16,
      content: '1',
      name: 'What We Do',
      link: '#whatwedo',
      section: 'anchors',
      properties: ['What We Do'],
    },
    logoRV: {
      properties: [],
      _id: '615e64e722ec422bd7fc1e5c',
      key: 'logoRV',
      name: 'logo RV Staffing',
      section: 'navBar',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-28T15:08:08.028Z',
      __v: 0,
      link: '#',
      file: './assets/RVStaffing_white_logo_png.png',
    },
    hiringprocess: {
      properties: [],
      _id: '615e66a922ec422bd7fc1e5d',
      key: 'hiringprocess',
      file: 'Hiring Process',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-28T15:09:13.246Z',
      __v: 1,
      content: '3',
      link: '#hiringprocess',
      name: 'Process',
      section: 'anchors',
    },
    missioncontrol: {
      _id: '615e66da22ec422bd7fc1e5e',
      key: 'missioncontrol',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-28T15:07:40.005Z',
      __v: 6,
      link: '#missioncontrol',
      name: 'Contact Mission Control',
      properties: ['true'],
      section: 'anchors',
      content: '4',
    },
    herobanner: {
      _id: '615e845722ec422bd7fc1e61',
      key: 'herobanner',
      name: 'Where Do You <br> Want To Go? ',
      section: 'Take your career to the next level with a job at Red Valley',
      properties: ['International Recruiting Services For The World-Minded.'],
      content: 'Show Me My Dream Job',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:56:42.129Z',
      __v: 0,
      link: '#jobs',
    },
    homeplace: {
      properties: [],
      _id: '615fc70c915fdaf76c5aee91',
      key: 'homeplace',
      name: 'A PLACE YOU CAN CALL HOME',
      section:
        "We're proud to offer benefits that help you fell your best and do your best.",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:56:13.799Z',
      __v: 0,
      content:
        "At RVS, we invest in your development so that you can reach your full potential. We promise that when you start you career with us, you'll never stop learning and you'll never stop being challenged.",
    },
    icon1: {
      _id: '615fcae7915fdaf76c5aee93',
      key: 'icon1',
      name: 'PRIVATE HEALTH INSURANCE',
      file: './assets/one-benefice.svg',
      section: 'icons',
      properties: ['alp3', 'alp2'],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:18:06.478Z',
      __v: 1,
    },
    icon2: {
      _id: '615fcf7978a16d9cd04fa34c',
      key: 'icon2',
      name: 'RAISES FOR OVER ACHIEVING / GOOD PERFORMANCE',
      file: './assets/icons/2.svg',
      section: 'icons',
      properties: ['alp2', 'alp3', ''],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:55:55.788Z',
      __v: 1,
    },
    icon3: {
      _id: '615fd07d78a16d9cd04fa34d',
      key: 'icon3',
      name: 'CONTINUOUS CAREER DEVELOPMENT',
      file: './assets/three-benefice.svg',
      section: 'icons',
      properties: ['alp3', 'alp3'],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:17:13.339Z',
      __v: 1,
    },
    icon4: {
      _id: '615fd0b278a16d9cd04fa34e',
      key: 'icon4',
      name: 'AUTO/MOTOR CYCLE INSURANCE DISCOUNTS',
      file: './assets/icons/4.svg',
      section: 'icons',
      properties: ['alp2', 'alp3', ''],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:55:56.156Z',
      __v: 1,
    },
    icon5: {
      _id: '615fd11278a16d9cd04fa34f',
      key: 'icon5',
      name: 'REFERRAL BONUSES',
      file: './assets/five-benefice.svg',
      section: 'icons',
      properties: ['alp3', 'alp3'],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:18:07.244Z',
      __v: 1,
    },
    icon6: {
      _id: '615fd13678a16d9cd04fa350',
      key: 'icon6',
      name: 'STATE OF THE ART HEADQUARTERS',
      file: './assets/icons/6.svg',
      section: 'icons',
      properties: ['alp2', 'alp2'],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:55:56.575Z',
      __v: 0,
    },
    icon7: {
      _id: '615fd14f78a16d9cd04fa351',
      key: 'icon7',
      name: 'FLEXIBLE WORK',
      file: './assets/seven-benefice.svg',
      section: 'icons',
      properties: ['alp3', 'alp3'],
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:18:07.610Z',
      __v: 1,
    },
    hiringProcess: {
      properties: [],
      _id: '615fd1d378a16d9cd04fa352',
      key: 'hiringProcess',
      name: 'THE HIRING PROCESS',
      content:
        "<p>Are you up form the challenge? Here's what you can expect:</p>\n<p>Scroll trought our open positions below, and if a description matches your dream job, click Apply Now to submit your application and resume</p>\n<p>If your expriencice aligns with the role, our Staffing Consultant team will invite you to an exploration call. We love when candidates come to these conversations with questions!</p>\n<p>Our inteviews are a two-way conversation. We will be curious about your expriencice, interests, aspirations. and what brigs out your best at work - and we want you to learn all abouts us too. do you want to be part of our team?</p>",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:20:44.339Z',
      __v: 0,
      file: './assets/hiring-process.jpeg',
    },
    accordion0: {
      properties: [],
      _id: '61609c518b7f63114cef7f89',
      key: 'accordion0',
      name: 'DEVELOPMENT <br> / DESIGN',
      section: 'accordion',
      link: '0',
      content:
        'Our team will ask you to demonstrate your knowledge and expertise through a number of conversations around your tech stack, coding, design, and development experience',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T08:20:57.566Z',
      __v: 0,
    },
    jobs: {
      properties: [],
      _id: '6161a742c5577a00a44d2697',
      key: 'jobs',
      name: 'JOBS',
      content:
        "At RV, our office is a place for collaboration, creativity, change and drive. Want to get Involved? Check out our current vacancies on the RV team and with some of the best companies int he US (and not because we're baised)!",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:57:33.305Z',
      __v: 0,
    },
    contact: {
      _id: '6161aaa7c5577a00a44d2698',
      key: 'contact',
      name: 'WANT TO GET IN TOUCH? <br/> LOOKING FOR <span class=\\"text-danger-personalized\\">INTERNSHIPS! </span>?',
      link: 'Talk Soon!',
      properties: [
        'Name',
        'Last Name',
        'E-mail',
        'Mobile',
        'Message',
        'Message sent!',
        'alert alert-info',
        'Message unsent, please try again',
        'alert alert-warning',
        '',
      ],
      content: "Drop us a note, and we'll be in contact with you soon!",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:57:45.989Z',
      __v: 2,
    },
    accordion1: {
      properties: [],
      _id: '6161d422c5577a00a44d2699',
      key: 'accordion1',
      name: 'SALES',
      section: 'accordion',
      link: '1',
      content:
        'Focus your ambition and fulfil your potential working with one of our bold, talented and knowledgeable sales teams.',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:57:25.774Z',
      __v: 0,
    },
    accordion2: {
      properties: [],
      _id: '6161d454c5577a00a44d269a',
      key: 'accordion2',
      name: 'OPERATIONS',
      section: 'accordion',
      link: '2',
      content:
        'Top professionals in HR and operations will assess your knowledge and problem-solving skills, and your ability to thrive in a fast-paced customer-centric team.',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T16:57:25.972Z',
      __v: 0,
    },
    accordion3: {
      _id: '6163892beb510e75160b059c',
      key: 'accordion3',
      name: 'FINANCE',
      file: '',
      section: 'accordion',
      link: '3',
      properties: [],
      content:
        'Money is the lifeblood of any business and our finance and accounting teams are at the nerve centre.',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-04T01:38:06.874Z',
      __v: 0,
    },
    accordion4: {
      properties: [],
      _id: '6161d4c6c5577a00a44d269c',
      key: 'accordion4',
      name: 'CREATIVE <br> & MARKETING',
      section: 'accordion',
      link: '4',
      content:
        "We believe creativity is intelligence having fun and that's exactly what our Creative and Marketing teams are and do. Our enthusedand talented creative teams deliver the unexpected and play a vital role in promoting RedValley and our amazing clients.",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-26T04:08:32.231Z',
      __v: 0,
    },
    logoAlt: {
      properties: [],
      _id: '6161d760c5577a00a44d269e',
      key: 'logoAlt',
      name: 'logo RV Staffing',
      file: './assets/RedValley_vertical_white_logo.png',
      section: 'footer',
      link: '#',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T19:11:22.576Z',
      __v: 0,
    },
    footer: {
      _id: '6161da81c5577a00a44d269f',
      key: 'footer',
      name: 'Sao Paulo Plaza',
      section: 'footer',
      link: 'recruiting@rvstaffing.com',
      content: 'Medellín, Colombia',
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-27T19:20:43.548Z',
      __v: 0,
      properties: ['Copyright 2021 RV/DEV', 'All Rights Reserved'],
    },
    about: {
      properties: [],
      _id: '6161e3e1c5577a00a44d26a0',
      key: 'about',
      name: 'WHAT WE DO',
      file: './assets/circles.png',
      content:
        "RVStaffing is a specialized staffing firm and RedValley's recruitment arm-- meaning our job is to source the best of the best to join our powerhouse family and work with our clients to supercharge their businesses. We work with some of the US and Canada's most innovative companies in the medical, legal health + wellness, logistics, marketing (and more) industries and pair them with Colombia's top talent... you!",
      createdAt: '2021-10-04T01:38:06.874Z',
      updatedAt: '2021-10-28T15:11:01.633Z',
      __v: 0,
    },
  },
};

const reducerLanding = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MEDIA':
      return Object.assign({}, state, {
        medias: action.allMedia,
      });
    case 'ADD_TEXTOS':
      return Object.assign({}, state, {
        medias: action.allMedia,
      });
    default:
      return state;
  }
};

export default createStore(reducerLanding);
