import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { connect } from 'react-redux';

const Contratacion = ({ contratacion, medias, accordion }) => {
  const validateMedias = () => {
    if (medias) {
      return { backgroundImage: 'url(' + medias.file + ')' };
    } else {
      return {};
    }
  };
  return (
    <>
      <Container
        fluid
        className='contratacion hidden-xs'
        id='hiringprocess'
        style={validateMedias()}
      >
        <Row className='row-full layout backgroundlayout4'>
          <Col md={6} className='section4 hiring'>
            <h2>{medias != null ? medias.name : contratacion.title}</h2>
            {medias != null ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: medias.content,
                }}
              ></span>
            ) : (
              contratacion.content.map((text) => <p>{text}</p>)
            )}
            {medias != null
              ? medias.properties !== []
                ? medias.properties.map((text) => <p>{text}</p>)
                : contratacion.content.map((text) => <p>{text}</p>)
              : contratacion.content.map((text) => <p>{text}</p>)}
          </Col>
          <Col md={6} className='section4 sectionaccordion'>
            <Accordion>
              {accordion !== ''
                ? accordion.map(
                    (accordion) =>
                      !!accordion.name.length && (
                        <Accordion.Item eventKey={accordion.link}>
                          <Accordion.Header className='acheader'>
                            {
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: accordion.name,
                                }}
                              ></span>
                            }
                          </Accordion.Header>
                          <Accordion.Body>{accordion.content}</Accordion.Body>
                        </Accordion.Item>
                      )
                  )
                : contratacion.accordion.map((accordion) => (
                    <Accordion.Item eventKey={accordion.key}>
                      <Accordion.Header className='acheader'>
                        {accordion.title}
                      </Accordion.Header>
                      <Accordion.Body>{accordion.text}</Accordion.Body>
                    </Accordion.Item>
                  ))}
            </Accordion>
          </Col>
        </Row>
        <div className='cuadrado'></div>
      </Container>
      <Container fluid className='contratacion visible-xs' id='hiringprocess2'>
        <Row className='row-full-hire layout-hire'>
          <Col className='section4 hiring'>
            <h2>{medias != null ? medias.name : contratacion.title}</h2>
            {medias != null ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: medias.content,
                }}
              ></span>
            ) : (
              contratacion.content.map((text) => <p>{text}</p>)
            )}
            <div className='cuadrado'></div>
          </Col>
        </Row>
        <Row className='row-full layout accordionrow'>
          <Col md={6} className='section4 sectionaccordion'>
            <Accordion>
              {accordion !== ''
                ? accordion.map(
                    (accordion) =>
                      !!accordion.name.length && (
                        <Accordion.Item eventKey={accordion.link}>
                          <Accordion.Header className='acheader'>
                            {
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: accordion.name,
                                }}
                              ></span>
                            }
                          </Accordion.Header>
                          <Accordion.Body>{accordion.content}</Accordion.Body>
                        </Accordion.Item>
                      )
                  )
                : contratacion.accordion.map((accordion) => (
                    <Accordion.Item eventKey={accordion.key}>
                      <Accordion.Header className='acheader'>
                        {accordion.title}
                      </Accordion.Header>
                      <Accordion.Body>{accordion.text}</Accordion.Body>
                    </Accordion.Item>
                  ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  contratacion: state.contratacion,
  medias: state.medias.hiringProcess,
  accordion: Object.values(state.medias)
    .filter((section) => section.section === 'accordion')
    .sort((a, b) => (a.key > b.key ? 1 : -1)),
});

export default connect(mapStateToProps)(Contratacion);
