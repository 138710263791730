import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const Jobs = ({ jobs, medias }) => {
  useEffect(() => {
    if (
      document.getElementById('rec_job_listing_div').childNodes[0].id ===
      undefined
    ) {
      const script = document.createElement('script');
      script.src = './embed_jobs.js';
      script.async = true;
      script.load = () => this.scriptLoaded();

      document.body.appendChild(script);
    }
  }, []);

  return (
    <Container fluid className='jobs' id='jobs'>
      <Row className='row-full layout'>
        <Col md={12} className='section5 sectionjobs'>
          <h3>{medias !== undefined ? medias.name : jobs.title}</h3>
          <h2>{medias !== undefined ? medias.content : jobs.subtitle}</h2>
          <div className='embed_jobs_head embed_jobs_with_style_3 embed_jobs_with_style'>
            <div className='embed_jobs_head2'>
              <div className='embed_jobs_head3'>
                <div id='rec_job_listing_div'> </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  jobs: state.jobs,
  medias: state.medias.jobs,
});

export default connect(mapStateToProps)(Jobs);
