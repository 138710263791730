import React, { useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Contact = ({ contact, medias }) => {
  const requiredText = 'This field is required';
  const formInstanceDesk = useRef();
  const formInstanceMobile = useRef();

  const validations = yup.object().shape({
    name: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    email: yup.string().email().required(requiredText),
    phonenumber: yup.string().required(requiredText),
    message: yup.string().required(requiredText),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      phonenumber: '',
      message: '',
    },
    validationSchema: validations,
    onSubmit: (values) => {
      handleSubmit(formik.form);
    },
  });

  const formSubmitDesk = async (e) => {
    e.preventDefault();
    return await handleSubmit(formInstanceDesk);
  };

  const formSubmitMobile = async (e) => {
    e.preventDefault();
    return await handleSubmit(formInstanceMobile);
  };

  const handleSubmit = async (formInstance) => {
    let body = {};
    const action = 'contact_us';
    const formData = new FormData(formInstance.current);
    formData.append('subject', 'Contact by Website RVStaffing');
    formData.append('to', 'webcontact@rvstaffing.com ');
    formData.append('cc', 'dev@rvdev.com');
    formData.append('site', 'https://rvstaffing.co/');
    formData.append('action', action);
    formData.forEach((value, key) => {
      body = { ...body, [key]: value };
    });

    const query = querystring.stringify(body);
    fetch('https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: query,
    })
      .then(() => {
        const rootElement = document.getElementById('output');
        const element = React.createElement(
          'div',
          { className: 'alert alert-info' },
          'Message Sent!'
        );
        formik.resetForm();
        ReactDOM.hydrate(element, rootElement);
      })
      .catch(console.log);
  };

  return (
    <Container fluid className='contactsection' id='missioncontrol'>
      <Row className='row-full layout'>
        <Col md={6} className='contact-content'>
          <h2 dangerouslySetInnerHTML={{ __html: contact.title }}></h2>
          <p>{medias !== undefined ? medias.content : contact.subtitle}</p>
        </Col>
        <Col md={6} className='contactform'>
          <form
            className='contact-form hidden-xs'
            id='form-desk'
            ref={formInstanceDesk}
            onSubmit={formSubmitDesk}
          >
            <Table bordered>
              <tbody>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[0].label}</label>
                  </td>
                  <td className='n-l-b'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        name='name'
                        className='form-control formfield smallw'
                        placeholder={contact.form[0].placeholder}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        style={{ border: 'none' }}
                        isInvalid={!!formik.errors.name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                  <td className='n-r-b n-l-b'>
                    <label className='labelform'>{contact.form[1].label}</label>
                  </td>
                  <td className='n-l-b n-r-b'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        name='lastName'
                        className='form-control formfield smallw'
                        placeholder={contact.form[1].placeholder}
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        style={{ border: 'none' }}
                        isInvalid={
                          !!formik.errors.lastName && formik.touched.lastName
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[2].label}</label>
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        type='email'
                        className='form-control formfield'
                        placeholder={contact.form[2].placeholder}
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isInvalid={
                          !!formik.errors.email && formik.touched.email
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[3].label}</label>
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        className='form-control formfield'
                        placeholder={contact.form[3].placeholder}
                        name='phonenumber'
                        onChange={formik.handleChange}
                        value={formik.values.phonenumber}
                        isInvalid={
                          !!formik.errors.phonenumber &&
                          formik.touched.phonenumber
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.phonenumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[4].label}</label>
                    <br />
                    <br />
                    <br />
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        className='form-control formfield'
                        placeholder={contact.form[4].placeholder}
                        name='message'
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        as='textarea'
                        isInvalid={
                          !!formik.errors.message && formik.touched.message
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <button type='submit' className='Btnformmail pull-right hidden-xs'>
              {contact.c2a}
              <div className='blackrowc2a'></div>
            </button>
          </form>
          <form
            className='contact-form visible-xs'
            id='form-mobile'
            ref={formInstanceMobile}
            onSubmit={formSubmitMobile}
          >
            <Table bordered>
              <tbody>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[0].label}</label>
                  </td>
                  <td className='n-l-b n-r-b'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        name='name'
                        className='form-control formfield'
                        placeholder={contact.form[0].placeholder}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        style={{ border: 'none' }}
                        isInvalid={!!formik.errors.name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-r-b n-l-b'>
                    <label className='labelform'>{contact.form[1].label}</label>
                  </td>
                  <td className='n-l-b n-r-b'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        name='lastName'
                        className='form-control formfield'
                        placeholder={contact.form[1].placeholder}
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        style={{ border: 'none' }}
                        isInvalid={
                          !!formik.errors.lastName && formik.touched.lastName
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[2].label}</label>
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        type='email'
                        className='form-control formfield'
                        placeholder={contact.form[2].placeholder}
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isInvalid={
                          !!formik.errors.email && formik.touched.email
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[3].label}</label>
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        className='form-control formfield'
                        placeholder={contact.form[3].placeholder}
                        name='phonenumber'
                        onChange={formik.handleChange}
                        value={formik.values.phonenumber}
                        isInvalid={
                          !!formik.errors.phonenumber &&
                          formik.touched.phonenumber
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.phonenumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='n-l-b n-r-b'>
                    <label className='labelform'>{contact.form[4].label}</label>
                    <br />
                    <br />
                    <br />
                  </td>
                  <td className='n-l-b n-r-b' colSpan='3'>
                    <Form.Group>
                      <Form.Control
                        className='form-control formfield'
                        placeholder={contact.form[4].placeholder}
                        name='message'
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        as='textarea'
                        isInvalid={
                          !!formik.errors.message && formik.touched.message
                        }
                        style={{ border: 'none' }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div align='left'>
              <button type='submit' className='Btnformmail visible-xs'>
                {contact.c2a}
                <div className='blackrowc2a'></div>
              </button>
            </div>
          </form>
          <div className="mt-4 mt-md-5 pt-md-4" id='output'></div>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  contact: state.contact,
  medias: state.medias.contact,
});

export default connect(mapStateToProps)(Contact);
